import { graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import {
  BulletTick,
  Button,
  Column,
  CurvedSectionDividerGlow,
  GatedContentForm,
  Grid,
  Gutter,
  HelmetSeo,
  HorizontalAlign,
  Layout,
  MaxWidth,
  Modal,
  PageMaxWidth,
  Section,
  Text,
} from "../components";
import { Color } from "../constants";
import { fluidImage } from "../prismic-tools";

export const query = graphql`
  query GatedContentPage($uid: String!) {
    prismicGatedContentPage(uid: { eq: $uid }) {
      data {
        hero_title
        hero_description
        hero_button_text

        pardot_form
        success_redirect_url

        section_title
        section_description
        section_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        section_bullets_title
        section_bullets {
          section_bullet_title
        }

        section_button_label

        seo_title
        seo_description
        share_image {
          alt
          dimensions {
            width
            height
          }
          url
        }
      }
    }
  }
`;

type Props = {
  data: GatsbyTypes.GatedContentPageQuery;
};

export default function GatedContentPage({ data }: Props) {
  const page = data.prismicGatedContentPage?.data;
  const [modal, setModal] = React.useState<boolean>(false);

  if (!page) throw new Error("Gated Content page missing");

  return (
    <Layout footerBackColor={Color.newDark2}>
      <HelmetSeo {...page} />
      <Section>
        <PageMaxWidth>
          <Gutter size={3} />
          <Text typeface="h1" as="h1" bold align="center">
            {page.hero_title}
          </Text>
          <Gutter size={2} />
          <MaxWidth width={750}>
            <HorizontalAlign align="center">
              <Text typeface="h5" align="center">
                {page.hero_description}
              </Text>
              <Gutter size={2} />
              <Button
                colorTheme="orange"
                label={page.hero_button_text}
                onClick={() => setModal(true)}
              />
            </HorizontalAlign>
          </MaxWidth>
          <Gutter size={4} md={2} />
        </PageMaxWidth>
        <CurvedSectionDividerGlow
          position="top"
          glowColor="orange"
          backColor="light1"
        />
        <Section background="dark">
          <Gutter size={2} md={1} />
          <PageMaxWidth>
            <MaxWidth width={750}>
              <HorizontalAlign align="center">
                <Text typeface="h3" align="center" color="light1" bold as="h2">
                  {page.section_title}
                </Text>
                <Gutter size={3} />
              </HorizontalAlign>
            </MaxWidth>
            <MaxWidth width={900}>
              <Grid>
                <Column size={6}>
                  <Text color="light1">{page.section_description}</Text>
                </Column>
                <Column size={6}>
                  <MaxWidth width={328}>
                    <Img fluid={fluidImage(page.section_image, 328)} />
                  </MaxWidth>
                </Column>
              </Grid>
              <Gutter size={3} />

              <Grid justifyColumns="space-between">
                <Column size={6}>
                  <Gutter />
                  <Text typeface="h3" color="light1" bold as="h3">
                    {page.section_bullets_title}
                  </Text>
                  <Gutter />
                </Column>
                <Column size={6}>
                  <Gutter />
                  {page.section_bullets?.map(
                    (b) =>
                      b && (
                        <BulletTick key={b.section_bullet_title}>
                          <Text color="light1">{b.section_bullet_title}</Text>
                        </BulletTick>
                      )
                  )}
                </Column>
              </Grid>
              <Gutter size={4} md={2} />

              <HorizontalAlign align="center">
                <Button
                  colorTheme="hollow-white"
                  label={page.section_button_label}
                  onClick={() => setModal(true)}
                />
              </HorizontalAlign>
              <Gutter size={4} />
            </MaxWidth>
          </PageMaxWidth>
        </Section>
      </Section>

      <Modal onDismiss={() => setModal(false)} visible={modal}>
        <GatedContentForm
          pardotForm={page.pardot_form || ""}
          formTitle={page.hero_title || ""}
        />
      </Modal>
    </Layout>
  );
}
